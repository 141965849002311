.sidebar {
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;
    -js-display: flex; 
    display: flex;
    flex-direction: column;
    flex-basis: 245px;
    padding-right: 30px;

    &--right {
        flex-basis: 272px;
        padding-right: 0;
    }

    &__block {
        padding-bottom: 20px;

        &--nomargin {
            padding: 0;
        }

        img {
            width: 100%;
            height: auto;
            
        }
    }

    &__block-header {
        display: block;
        color: $black;
        font-size: 18px;
        margin-bottom: 14px;
        text-align: center;
    }

    &__link-block {
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        display: block;
        height: 151px;
        margin-bottom: 7px;
        padding: 5px 5px 0;
        width: 260px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 13px;
        font-weight: bold;
        line-height: 23px;

        a {
            color: $green;
            text-decoration: none;
        }

        &__link-block-title {
            display: block;
        }
    }

    &__news-block {
        box-shadow: 0 10px 20px -8px rgb(194, 194, 194);
        padding: 14px 17px 0 17px;
        background-image: linear-gradient(180deg, #F1F1F1, #FFFFFF);
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__news-block-item {
        margin-bottom: 5px;
        flex-basis: 30%;
    }

    &__news-block-item-date {
        color: $green;
        font-size: 18px;
    }

    &__news-block-item-text {
        color: $black;
        font-size: 12px;
        text-decoration: none;

        &:hover {
            color: $green;
            text-decoration: underline;
        }
    }

    &__news-block-button-wrapper {
        flex: 100% 1 1;
        text-align: center;
        margin-top: 20px;
    }

    &__news-block-button {
        width: 130px;
        padding-left: 42px;

        i {
            background: url(../img/archive.png) no-repeat 0 0;
            display: inline-block;
            height: 21px;
            left: 36px;
            position: absolute;
            top: 5px;
            width: 18px;
        }
    }

    &__menu {
        padding: 0 0 20px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                border-bottom: 1px solid #d8d8d8;
                padding: 0 5px;

                &:last-child {
                    border-bottom: none;
                }

                a {
                    color: $green;
                    font-size: 11px;
                    line-height: 20px;
                    font-weight: bold;
                    text-decoration: none;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &.active {

                    a {
                        color: $black;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 767px) {
    .sidebar {
        -js-display: flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: none;
        padding-right: 0;

        &__block {
            padding-bottom: 40px;

            &:first-child {
                margin-right: 20px;
            }

            img {
                width: auto;
                height: auto;
                
            }
        }

        &__block-header {
            display: block;
            color: $black;
            font-size: 18px;
            margin-bottom: 14px;
        }
    }
}