.page {
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;

    &__wrapper {
        width: 1090px;
        margin: 0 auto;
    }

    &__main {
        -js-display: flex; 
        display: flex;
        flex-direction: row-reverse;
        padding-top: 5px;

        &--home {
            flex-direction: row;
        }
    }


}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .page {

        &__wrapper {
            width: 96%;
            padding: 0 2%;
        }
    }
}

@media screen and (max-width: 767px) {
    .page {

        &__wrapper {
            width: 90%;
            padding: 0;
        }

        &__main {
            flex-direction: column;
        }

    }
}