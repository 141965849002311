.wide-banner {
    max-width: 1200px;
    width: 100%;

    &--1280px {
        max-width: 1280px;
        padding-bottom: 20px;
    }

    img {
        width: 100%;
        height: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .wide-banner {
        max-width: 1200px;
        width: 96%;

        img {
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (max-width: 767px) {
    .wide-banner {

        &--1280px {
            display: none;
        }
    }
}