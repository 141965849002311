.block-reviews {
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;

    &__img {
        text-align: center;

        img {
            width: 50%;
        }
    }

    &__link-wrapper {
        text-align: center;
    }

    &__link {
        font-size: 12px;
    }
}