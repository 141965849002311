.block-forum {
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;

    &__list {
        height: 185px;
        border-top: 1px solid #d2d2d1;
        border-bottom: 1px solid #d2d2d1;
    }
    
    &__list-item {
        font-size: 12px;
        line-height: 12px;
        border-bottom: 1px dotted #d2d2d2;
        color: #353535;
        display: block;
        padding: 8px 0 8px 7px;
        text-decoration: none;
        vertical-align: middle;

        &:hover {
            color: $green;
            text-decoration: underline;
        }

        i {
            display: block;
            background: url(../img/comment-icon.png) no-repeat 0 6px;
            color: #ff7e00;
            float: right;
            font-style: normal;
            line-height: 30px;
            height: 34px;
            margin-top: -7px;
            padding-left: 26px;
            width: 17px;
        }
    }

    &__button {
        width: 130px;
        padding-left: 42px;

        i {
            background: url(../img/forum-icon.png) no-repeat 0 0;
            display: inline-block;
            height: 21px;
            left: 27px;
            position: absolute;
            top: 5px;
            width: 21px;
        }
    }

    &__button-wrapper {
        text-align: center;
        margin: 10px 0;
    }
}