.page-footer {
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;
    -js-display: flex; 
    display: flex;
    padding: 20px 0;

    &__info {
        flex-basis: 265px;
    }

    &__copyright {
        color: #838383;
        font-size: 11px;
        font-weight: bold;
        line-height: 19px;
        padding: 20px 0 30px;
    }

    &__social {

    }

    &__social-link {
        background: url(../img/social.png) no-repeat;
        display: inline-block;
        height: 27px;
        margin-right: 10px;
        width: 26px;

        &--fb {
            background-position: 0 0;
        }

        &--twitter {
            background-position: -35px 0;
        }

        &--youtube {
            background-position: -71px 0;
        }

        &--instagram {
            background: url(../img/instagram.png) no-repeat;
            height: 26px;
            margin-right: 10px;
            width: 26px;
        }
    }

    &__main-menu {
        flex-basis: 265px;
        padding: 15px 0 0 10px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 130px;

            li {
                border-bottom: 1px solid #d8d8d8;

                &:last-child {
                    border-bottom: none;
                }

                a {
                    color: $green;
                    font-size: 11px;
                    line-height: 20px;
                    font-weight: bold;
                    text-decoration: none;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

    }

    &__catalog-menu {
        flex-basis: 545px;
        padding-top: 15px;
    }

    &__catalog-menu-header {
        display: block;
        border-bottom: 1px solid #d5d5d5;
        color: $green;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    &__catalog-menu-items {
        -js-display: flex; 
        display: flex;
    }

    &__catalog-menu-items-col {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 272px;

        li {
            a {
                color: #838383;
                font-size: 11px;
                font-weight: bold;
                line-height: 20px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .page-footer {

        &__main-menu {
            flex-basis: 25%;

        }

        &__catalog-menu {
            flex-basis: 50%;
        }

        &__catalog-menu-items {
            flex-wrap: wrap;
        }

        &__catalog-menu-items-col {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 50%;
        }
    }
}

@media screen and (max-width: 767px) {
    .page-footer {
        flex-direction: column-reverse;
        padding: 0;

        &__info {
            flex: none;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &__main-menu {
            flex-basis: 100%;
            padding-left: 0;
        }

        &__catalog-menu {
            flex-basis: 100%;
        }

        &__catalog-menu-items {
            flex-wrap: wrap;
        }

        &__catalog-menu-items-col {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }
}