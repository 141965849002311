/*Bigzee skin*/

.scrollable.bigzee-skin {
  padding-right: 10px;
  padding-bottom: 6px;
}

.scrollable.bigzee-skin .scroll-bar.vertical {
  background-color: #ecedee;
  width: 8px;
}

.scrollable.bigzee-skin .scroll-bar.horizontal {
  height: 8px;
}

.scrollable.bigzee-skin .scroll-bar .thumb {
  width: 6px;
  background-color: rgba(255,255,255,0.75);
  border: 1px solid #948e8e;
  border-radius: 3px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.scrollable.bigzee-skin .scroll-bar:hover .thumb {

}

.seven-side {
  display: none;
  margin-left: auto;
  margin-right: auto;
}


@media screen and (max-width: 1200px) {
    .seven-wide {
      display: none;
    }

    .seven-side {
      display: block;
    }
}