.block-seven {
    font-family: $main-font;
    font-size: $main-size;
    line-height: $line-height;

    background: url(../img/mini-seven-bg.jpg) no-repeat;
    box-shadow: 3px 10px 20px 2px rgb(223, 223, 223);
    height: 252px;
    margin-bottom: 30px;
    padding: 67px 35px 20px 40px;
    position: relative;
    width: 173px;

    &--wide {
        width: 1090px;
    }

    &__header {
        color: $black;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 24px;
        text-transform: uppercase;
    }

    &__items {
        color: $green;
        font-size: 11px;
        font-weight: bold;
        line-height: 17px;
        text-transform: uppercase;
    }

    &__item {
        display: none;

        &--active {
            display: block;
        }
    }

    &__numbers {
        bottom: 20px;
        position: absolute;
        font-size: 0;
    }

    &__number {
        color: #bab9b9;
        cursor: pointer;
        margin-right: 6px;
        font-size: 32px;
        font-weight: bold;

        &--active {
            color: $green;
        }
    }
}