.form {
    padding: 40px 0;

    form {
        display: flex;
        flex-direction: column;
    }

    &__header {
        font-size: 18px;
        font-weight: normal;
        margin: 10px 0;
    }

    &__field {
        margin-bottom: 12px;
        
        input, textarea {
            width: 100%;
            margin-bottom: 3px;
            padding: 5px;            
        }

    }

    &__field-textarea {
        textarea {
            min-height: 150px;            
        }
    }

    &__button {
        width: 150px;
        align-self: center;
    }
}