.btn {
    background: -webkit-linear-gradient(#ff9d18 0%, #ff7900 100%);
    background: -o-linear-gradient(#ff9d18 0%, #ff7900 100%);
    background: linear-gradient(#ff9d18 0%, #ff7900 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9d18', endColorstr='#ff7900',GradientType=0 );
    border: 1px solid #bd6b2f;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0,0,0,0.3);
    width: 200px;
    text-align: center;

    &:hover {
        background: -webkit-linear-gradient(#ffb146 0%, #ff9433 100%);
        background: -o-linear-gradient(#ffb146 0%, #ff9433 100%);
        background: linear-gradient(#ffb146 0%, #ff9433 100%);
    }

    &:active {
        background: -webkit-linear-gradient(#ff7900 0%, #ff9d18 100%);
        background: -o-linear-gradient(#ff7900 0%, #ff9d18 100%);
        background: linear-gradient(#ff7900 0%, #ff9d18 100%);
    }
}