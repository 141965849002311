.gallery {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    &__items {
        flex-basis: 550px;
        height: 235px;
    }

    &__item {
        display: none;
        text-align: center;

        &--active {
            display: block;
        }

        img {
            width: initial;
            height: auto;
            max-height: 235px;
        }

    }

    &__thumbs {
        flex-basis: 194px;
    }

    &__thumb {
        display: block;
        border: 1px solid #dadada;
        margin-bottom: 10px;
        width: 194px;
        height: 102px;
        text-align: center;

        img {
            width: initial;
            height: auto;
            max-height: 92px;
        }


        &--active {
            border-color: $green;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .gallery {
        display: flex;
        justify-content: space-between;
        padding-bottom: 40px;
        &__items {
            height: 235px;
        }

        &__item {
            display: none;
            text-align: center;

            &--active {
                display: block;
            }

            img {
                width: initial;
                height: auto;
                max-height: 235px;
            }

        }

        &__thumbs {
            flex-basis: 120px;

        }

        &__thumb {
            display: block;
            border: 1px solid #dadada;
            margin-bottom: 10px;
            width: 194px;
            height: 102px;
            text-align: center;

            img {
                width: initial;
                height: auto;
                max-height: 92px;
            }


            &--active {
                border-color: $green;
            }
        }
    }
}

@media screen and (min-width: 500px) and (max-width: 1023px) {
    .gallery {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        &__items {
            flex-basis: 235px;
            margin-bottom: 20px;
        }

        &__item {
            display: none;
            text-align: center;

            &--active {
                display: block;
            }

            img {
                width: initial;
                height: auto;
                max-height: 235px;
            }

        }

        &__thumbs {
            flex-basis: 120px;
            display: flex;
            justify-content: space-between;
        }

        &__thumb {
            width: 125px;
            height: 102px;
            img {
                width: initial;
                height: auto;
                max-height: 92px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .gallery {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        &__items {
            flex-basis: 200px;
            margin-bottom: 20px;
        }

        &__item {
            display: none;
            text-align: center;

            &--active {
                display: block;
            }

            img {
                width: initial;
                height: auto;
                max-height: 200px;
            }

        }

        &__thumbs {
            flex-basis: 85px;
            display: flex;
            justify-content: space-between;
        }

        &__thumb {
            width: 85px;
            height: 85px;
            img {
                width: initial;
                height: auto;
                max-height: 55px;
            }
        }
    }
}